import axios from 'axios';

export default class BookResource {
  books(page, perPage) {
    return axios.get('/api/books?page=' + page + '&per_page=' + perPage);
  }

  book(bookId) {
    return axios.get('/api/books/' + bookId);
  }

  onlineBooks() {
    return axios.get('/api/online-books');
  }


  downloadFile(file){

    return axios.post('/api/online-books/downloadFile',{file},{responseType:'blob'})

     

  }
}
